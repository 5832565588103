import React from 'react'
import '../../App.css';

function AboutPage() {
    return (
        <div>
            AboutPage
        </div>
    )
}

export default AboutPage
