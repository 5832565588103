import React from 'react';
import '../../App.css'

import ContactBody from '../ContactBody.js'

function ContactPage() {
    return(
        <>
            <ContactBody/>
        </>
    );
}

export default ContactPage;